define([], function () {

  class enhancedEcommerce {

    addToBasket(currency, name, productID, price, qty, brand, fulfilment, inStockWarehouse, collectionType, category, homeDeliveryAvailable, range) {
      let updatedPrice = price.replace('&#163;', '');
      updatedPrice = updatedPrice.replace('£', '');
      let warehouseStock = 'out'
      if(inStockWarehouse === 'true' || inStockWarehouse === true) {
        warehouseStock = 'in';
      }
      if(homeDeliveryAvailable === true) {
        homeDeliveryAvailable = 'true';
      } else if(homeDeliveryAvailable === false) {
        homeDeliveryAvailable = 'false';
      }

      if(brand === undefined) {
        brand = range;
      }

      window.dataLayer.push({
        'event': 'addToCart',
        'ecommerce': {
          'currencyCode': currency,
          'add': {
            'products': [{
              'name': name,
              'id': productID,
              'price': updatedPrice,
              'quantity': qty,
              'brand': brand,
              'dimension3': fulfilment,
              'dimension48': warehouseStock,
              'dimension45': collectionType,
              'category': category,
              'dimension46': homeDeliveryAvailable,
              'range': range,
            }]
          }
        }
      });
    }

    removeFromBasket(name, productID, price, qty, brand, fulfilment, inStockWarehouse, collectionType, category, homeDeliveryAvailable, range) {
      let updatedPrice = price.replace('£', '');
      let warehouseStock = 'out'
      if(inStockWarehouse === 'true') {
        warehouseStock = 'in';
      }

      if(brand === undefined || brand === '' ) {
        brand = range;
      }

      window.dataLayer.push({
        'event': 'removeFromCart',
        'ecommerce': {
          'remove': {
            'products': [{
              'name': name,
              'id': productID,
              'price': updatedPrice,
              'quantity': qty,
              'brand': brand,
              'dimension3': fulfilment,
              'dimension48': warehouseStock,
              'dimension45': collectionType,
              'category': category,
              'dimension46': homeDeliveryAvailable,
              'range': range,
            }]
          }
        }
      });
    }

    basketView(products) {
      let productsList = [];
      products.forEach((product) => {
        let singleProduct = {};
        const removeButton = product.querySelector('.v3ResponsiveBasket_removeItem') || product.querySelector('.athenaBasket_removeItem');
        singleProduct['quantity'] = removeButton.getAttribute('data-product-qty');
        singleProduct['name'] = removeButton.getAttribute('data-product-name');
        singleProduct['price'] = removeButton.getAttribute('data-product-price').replace('£', '');
        singleProduct['dimension45'] = removeButton.getAttribute('data-collection-type');
        singleProduct['dimension46'] = removeButton.getAttribute('data-product-deliverable');
        singleProduct['category'] = removeButton.getAttribute('data-product-category');
        singleProduct['range'] = removeButton.getAttribute('data-product-range');
        if(removeButton.getAttribute('data-product-warehouse-stock') === 'true') {
          singleProduct['dimension48'] = 'in';
        } else {
          singleProduct['dimension48'] = 'out';
        }
        let sku = removeButton.getAttribute('data-product-id');
        if(removeButton.getAttribute('data-product-externalSku')) {
          sku = removeButton.getAttribute('data-product-externalSku');
        }
        singleProduct['id'] = sku;
        singleProduct['dimension3'] = removeButton.getAttribute('data-product-fulfilment');
        productsList.push(singleProduct);
      })
      window.dataLayer.push({
        'event': 'checkout',
        'ecommerce': {
          'checkout': {
            'actionField': {'step': 1},
            'products': productsList
          }
        }
      });

      window.dataLayer.push({
        'event': 'view_cart',
        'ecommerce': {
          'value': window.dataLayer[0].basketTotal,
          'items': productsList
        }
      });

    }

    view_item_list(products) {
      let productsList = [];
      products.forEach((product) => {
        let singleProduct = {};
        const productListItem = product.querySelector('.js-enhanced-ecommerce-data');

        if (productListItem) {
          singleProduct['name'] = productListItem.getAttribute('data-product-title');
          singleProduct['price'] = productListItem.getAttribute('data-product-price').replace('£', '');
          singleProduct['id'] = productListItem.getAttribute('data-product-id');
          singleProduct['brand'] = productListItem.getAttribute('data-product-brand');
          singleProduct['list_position'] = productListItem.getAttribute('data-product-position');
          singleProduct['list_name'] = window.dataLayer[0].pageTitle;

          productsList.push(singleProduct);
        }
      })
      window.dataLayer.push({
        'event': 'view_item_list',
        'ecommerce': {
          'list': {
            'products': productsList
          }
        }
      });
    }

    productView(productName, externalSku, productPrice, productBrand, inStock, inStockWarehouse, homeDeliveryAvailable,
      clickAndCollect, checkStock, noCheckStock, collectionType, category, range) {
      let isInStockWarehouse = 'out';
      let hasHomeDeliveryAvailable = 'Not available for delivery';
      let isCheckStock = 'No check stock';
      let fulfilment = 'Not Available'
      if(inStockWarehouse === 'true') {
        isInStockWarehouse = 'in';
      }
      if(inStockWarehouse === 'true' && homeDeliveryAvailable === 'true') {
        hasHomeDeliveryAvailable = 'In stock';
      } else if (homeDeliveryAvailable === 'true' && inStockWarehouse === 'false') {
        hasHomeDeliveryAvailable = 'Out of stock';
      }
      if(clickAndCollect === 'true' || checkStock === 'true') {
        isCheckStock = 'Can check stock'
      }
      if(clickAndCollect === 'true') {
        fulfilment = 'Available to order'
      } else if(checkStock === 'true') {
        fulfilment = 'Check stock'
      } else if(noCheckStock === 'true') {
        fulfilment = 'No Check Stock'
      }

      let product = {
        'name': productName,
        'id': externalSku,
        'price': productPrice,
        'brand': productBrand,
        'dimension48': isInStockWarehouse,
        'Home Delivery': hasHomeDeliveryAvailable,
        'In-Store': fulfilment,
        'dimension46': homeDeliveryAvailable,
        'dimension45': collectionType,
        'category': category,
        'range': range
      }

      if(inStockWarehouse && homeDeliveryAvailable) {
        product['Home deliverable'] = isCheckStock
      } else {
        product['Not available for delivery'] = isCheckStock;
      }

      window.dataLayer.push({
        'event': 'productView',
        'ecommerce': {
          'detail': {
            'products': [product]
          }
        }
      });
    }

    stockCheck(stores, storesAvailable, productID) {
      let fiveStoreInfo = {};
      fiveStoreInfo['event'] = 'fiveStoreInfo';
      fiveStoreInfo['datav3-stock-availability-total-stores-shown'] = stores.length;
      fiveStoreInfo['datav3-stock-availability-total-available'] = storesAvailable.length;
      fiveStoreInfo['datav3-stock-availability-product-id'] = productID;
      stores.forEach((store, i) => {
        let storeID = store.getAttribute('data-store-id');
        let storeName = store.querySelector('.storeAvailability_storeName').innerHTML;
        const stockMessage = store.querySelector('.storeAvailability_stockMessage');
        let stockNumber = stockMessage.getAttribute('data-stock-number');
        let reservable = store.getAttribute('data-click-and-collect');
        let recoveryShown = store.getAttribute('data-deliver-to-store');
        if(store.querySelector('.storeAvailability_inStock')) {
          fiveStoreInfo['datav3-stock-availability-status-' + (i+1)] = 'available';
        } else if(store.querySelector('.storeAvailability_outOfStock')) {
          fiveStoreInfo['datav3-stock-availability-status-' + (i+1)] = 'outOfStock';
        } else {
          fiveStoreInfo['datav3-stock-availability-status-' + (i+1)] = 'notInThisStore';
        }
        fiveStoreInfo['datav3-stock-availability-store-id-' + (i+1)] = storeID;
        fiveStoreInfo['datav3-stock-availability-store-name-' + (i+1)] = storeName;
        fiveStoreInfo['datav3-stock-availability-store-quantity-' + (i+1)] = stockNumber;
        fiveStoreInfo['datav3-stock-availability-reservable-' + (i+1)] = reservable;
        fiveStoreInfo['datav3-stock-availability-store-recovery-shown-' + (i+1)] = recoveryShown;
      });

      window.dataLayer.push(
        fiveStoreInfo
      );
    }
  }

  return new enhancedEcommerce();
});
